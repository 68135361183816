<template>
  <div
    :class="['get-ticket', isSafari ? 'safariStyle' : '']"
    v-if="!webDisabled"
  >
    <div class="ticket-content" v-if="batchId">
      <div class="header-img">
        <img v-if="data.promotionImg" :src="data.promotionImg" alt="" />
      </div>
      <div class="container">
        <div class="ticket-con">
          <div class="ticket-info">
            <div class="ticket-detail">
              <div class="ticket-amount">
                ¥
                <span class="ticket-money">{{ data.amount || "" }}</span>
                抵扣金额
              </div>
              <div v-if="!data.isCanReceive" class="tip">活动已结束</div>
            </div>
            <div class="ticket-name">
              <span class="info-title">{{ data.couponName || "" }}</span>
              <span class="icon">商品抵扣券</span>
            </div>

            <div class="ticket-time">
              可用商品：{{ data.productName || "" }} ;
              {{ data.specName || "" }} ;
              {{ data.attrName || "" }}
            </div>
            <div class="ticket-time">
              有效期：{{ `${data.startDate || ""}-${data.endDate || ""}` }}
            </div>
          </div>
          <div class="use-info">
            <h2>领取及使用说明：</h2>
            <div class="info-text" v-html="data.instructions"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="ticket-footer" v-if="batchId">
      <div class="tip" v-if="!data.isCanReceive">抱歉，当前活动已结束~</div>
      <div class="btn">
        <van-button class="get-btn" type="primary" @click="clickBtn">{{
          data.isCanReceive ? "立即领取" : "更多优惠商品"
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import { GetCouponInfoByCardNumber } from "@/api/common";
import { mapState, mapMutations } from "vuex";
import mathManage from "@/utils/mathManage";
export default {
  components: {
    [Button.name]: Button,
  },
  data() {
    // 判断是否在Safari浏览器中
    var ua = navigator.userAgent.toLowerCase();
    let isSafari = false;
    if (
      ua.indexOf("applewebkit") > -1 &&
      ua.indexOf("mobile") > -1 &&
      ua.indexOf("safari") > -1 &&
      ua.indexOf("linux") === -1 &&
      ua.indexOf("android") === -1 &&
      ua.indexOf("chrome") === -1 &&
      ua.indexOf("ios") === -1 &&
      ua.indexOf("browser") === -1
    ) {
      isSafari = true;
    } else {
      isSafari = false;
    }
    return {
      data: {},
      groupId: "",
      batchId: "",
      isSafari,
    };
  },
  computed: {
    ...mapState([
      "loading",
      "noPage",
      "isLogin",
      "isInWx",
      "webDisabled",
      "mallInfo",
    ]), //isLogin 判断是否登录, isInWx 判断是否是微信环境
  },

  watch: {
    mallInfo: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (newVal && newVal.mallName != oldVal.mallName) {
          document.title = newVal.mallName;
        }
      },
    },
  },

  created() {
    if (this.webDisabled) {
      return;
    }
    const code = this.$route.query.code;
    this.code = code;
    this.getCouponInfo(code);
  },

  mounted() {
    document.title = this.mallInfo.mallName;
  },

  methods: {
    ...mapMutations(["setLoginStatus"]),
    // 获取卡券信息
    async getCouponInfo(code) {
      const res = await GetCouponInfoByCardNumber({
        code: encodeURIComponent(code),
      });
      if (res && res.code == 0) {
        this.data = res.data || {};
        this.batchId = res.data.batchId;
        document.title = `${this.mallInfo.mallName}${
          res.data.couponName ? "-" + res.data.couponName : ""
        }`;
        this.$nextTick(() => {
          const login = mathManage.getParam("login");
          // login  表示从认证中心回来  自动提交领取
          if (login) {
            this.clickBtn();
          }
        });
      } else {
        this.$toast(res.message);
        setTimeout(() => {
          this.$router.push("/mall/home");
        }, 2000);
      }
    },

    clickBtn() {
      // 不能领取状态
      if (!this.data.isCanReceive) {
        this.$router.replace("/mall/goods");
        return;
      }
      // 如果在微信中且没有登录
      if (this.isInWx && !this.isLogin) {
        const { protocol, host } = location;
        // 拼接url  并跳转到认证中心
        const nowHref = encodeURIComponent(
          `${protocol}//${host}/ticket?login=1&code=${this.code}`
        );
        this.$wxLogin(nowHref);
        return;
      }
      this.$router.push({
        path: "/mall/home",
        query: {
          ticketCode: this.batchId,
          productId: this.data.upperProductId,
          productType: this.data.productType,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.get-ticket {
  height: 100vh;
  overflow-y: auto;
  background-color: #fff;
  position: relative;
  padding-bottom: 1rem;

  &.safariStyle {
    padding-bottom: 2.5rem;
  }

  .ticket-content {
    overflow-y: auto;

    .ticket-header {
      position: relative;
      height: 0.88rem;
      font-size: 0.36rem;
      font-weight: 700;
      font-family: PingFangSC-Medium, PingFang SC;
      text-align: center;
      > img {
        width: 0.48rem;
        position: absolute;
        left: 0.36rem;
        top: 50%;
        transform: translateY(-50%);
      }
      .ticket-title {
        line-height: 0.88rem;
      }
    }
    .header-img {
      width: 100%;
      height: 4.48rem;
      // background: #fff8e6;
      position: relative;
      z-index: 1;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .container {
    position: relative;
    top: -0.3rem;
    border-radius: 0.24rem 0.24rem 0rem 0rem;
    z-index: 2;
    height: auto;
    background-color: #fff;

    .ticket-con {
      padding: 0.32rem 0.4rem;
      box-sizing: border-box;

      .ticket-info {
        border-bottom: 0.01rem solid #f9f9f9;
        padding: 0;

        .ticket-detail {
          display: flex;
          justify-content: space-between;
          height: 0.48rem;
          align-items: center;

          .ticket-amount {
            font-size: 0.28rem;
            color: #ff0100;
            font-family: PingFangSC-Medium, PingFang SC;

            > .ticket-money {
              font-size: 0.48rem;
            }
          }
          .tip {
            padding: 0 0.2rem;
            height: 0.4rem;
            background: #cdc7c7;
            border-radius: 0.2rem;
            font-size: 0.2rem;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .ticket-name {
          margin: 0.24rem 0;
          .info-title {
            margin-right: 0.24rem;
            width: 2.36rem;
            height: 0.32rem;
            font-size: 0.32rem;
            font-family: PingFangSC-Medium, PingFang SC;
            color: #333333;
          }
          .icon {
            height: 0.34rem;
            font-size: 0.2rem;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            margin-right: 0.08rem;
            color: #ffffff;
            display: inline-block;
            padding: 00.04rem;
            background: linear-gradient(117deg, #ff7d58 0%, #ff503f 100%);
            border-radius: 0.04rem;
            display: inline-block;
            line-height: 0.3rem;
            // align-items: center;
          }
          font-size: 0.32rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
        }
        .ticket-time {
          font-size: 0.22rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-bottom: 0.12rem;
        }
      }

      .use-info {
        padding: 0.32rem 0;
        h2 {
          font-size: 0.28rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 700;
          color: #333333;
          margin-bottom: 0.28rem;
        }

        .info-text {
          font-size: 0.28rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9b9b9b;
        }
        .detials {
          margin-top: 0.3rem;
        }
      }
    }
  }

  .ticket-footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 20;
    background-color: #fff;
    .tip {
      height: 0.56rem;
      background: #010100;
      font-size: 0.26rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffdab0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn {
      width: 100%;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 0.4rem;
      box-sizing: border-box;
    }
    .get-btn {
      width: 100%;
      height: 0.8rem;
      background: linear-gradient(90deg, #ff9577 0%, #ff3a20 100%);
      border-radius: 0.4rem;
      font-size: 0.36rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      border-color: #ff9577;
    }
  }
}
</style>
